import { useStore } from 'vuex';
import useModal from '@/composables/use-modal';
import { useMenuStore } from '@/store/pinia/menu/menu';
import { navigate } from 'vite-plugin-ssr/client/router';
import { modalName } from '@/components/Login/consts';

interface RedirectMapItem {
  from: string;
  to: string;
  withParams?: boolean;
  isCalculate?: boolean;
  isModal?: boolean;
}

const redirectMap: RedirectMapItem[] = [
  {
    from: 'gifts',
    to: 'cabinet/gifts/',
  },
  {
    from: 'box',
    to: 'cabinet/calculate/',
    isCalculate: true,
  },
  {
    from: 'profi',
    to: 'cabinet/calculate/',
    isCalculate: true,
  },
  {
    from: 'tracking',
    to: 'cabinet/tracking/',
    withParams: true,
  },
  {
    from: 'payment',
    to: 'payment/',
    isModal: true,
  },
];

export const preparePathname = (url: string, locale: string) => {
  const endSlash = url.endsWith('/') ? '' : '/';

  return `/${locale}/${url}${endSlash}`;
};

export function useRedirect() {
  const store = useStore();
  const modal = useModal();
  const menuStore = useMenuStore();

  const startsWith = (pathname: string) => {
    return window.location.pathname.startsWith(
      preparePathname(pathname, store.getters.currentLocale),
    );
  };

  return () => {
    const foundRedirect = redirectMap.find((item) => {
      return startsWith(item.from) ? item : false;
    });

    if (foundRedirect) {
      const params = foundRedirect.withParams ? window.location.search : '';

      if (foundRedirect.isModal) {
        modal.hide(modalName);
        menuStore.updateMenuForAuthorized(store.getters.currentLocale);
        navigate(preparePathname(foundRedirect.to, store.getters.currentLocale));
        return;
      }

      window.location.href =
        preparePathname(foundRedirect.to, store.getters.currentLocale) + params;
      return;
    }

    try {
      const urlParams = new URLSearchParams(window.location.search);
      const redirect = urlParams.get('redirect') ? atob(<string>urlParams.get('redirect')) : null;

      // Делаем проверку, что редирект только внутри сайта – начинаете на /, но не // и не http
      if (redirect && redirect.match(/^\/[a-z]/)) {
        window.location.search = '';
        window.location.href = redirect;
        return;
      }

      // Чистим лишнее
      window.history.replaceState(null, '', window.location.pathname);
    } catch (e) {
      /* empty */
    }

    window.location.pathname = preparePathname('cabinet', store.getters.currentLocale);
  };
}
